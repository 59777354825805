/* eslint-disable max-lines */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { React, Theme } from '@/app'
import { View, Logo, Text, TextInput, Button, Image } from '@/components'
import { useState } from 'react'
import * as yup from 'yup'
import { createForm, useForm } from '@codeleap/common'
import { variantProvider } from '@/app/theme'
import { APIClient } from '@/services'

export const Banner = () => {
  const newsletter = APIClient.Profile.useProfile({ })

  const newsLetterform = createForm('newsletter', {
    email: {
      type: 'text',
      validate: yup.string().required('This cannot be empty').email('Invalid email'),
    },
  })

  const form = useForm(newsLetterform, {
    output: 'json',
    validateOn: 'none',
  })

  const handleCreateNewsletter = () => {
    const isValid = form.validateAll(true)

    if (isValid) {
      newsletter.newsLetterQuery.mutateAsync({
        email: `${form.values.email}`,
      })
    }
    form.setFieldValue('email', '')
  }

  return (
    <View css={styles.wrapper} variants={['gap:2', 'marginVertical:12']} responsiveVariants={{
      mid: ['marginVertical:4'],
    }} >
      <Image objectFit='fill' css={styles.image} source={'newslatter.png'} />
      <View variants={['column', 'justifySpaceBetween', 'paddingVertical:2', 'gap:2', 'paddingRight:2']} css={styles.innerWrapper} responsiveVariants={{
        mid: ['paddingHorizontal:2'],
      }}>
        <View variants={['column', 'gap:3']}>
          <Text text={"Don't miss out!"} variants={['h2', 'black800']} responsiveVariants={{
            mid: ['textCenter'],
          }} />
          <Text text={'Register now to gain early access'} variants={['black']} responsiveVariants={{
            mid: ['textCenter'],
          }}/>
        </View>
        <View variants={['gap:1']} responsiveVariants={{
          mid: ['column'],
        }}>
          <TextInput {...form.register('email')} styles={{
            wrapper: styles.inputWrapper,
            'textField': styles.input, 
            label: { display: 'none' },
          }}
          placeholder={'Email'}
          />
          <Button text={'Subscribe'} variants={['blue']} onPress={handleCreateNewsletter} css={styles.button} />
        </View>
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      backgroundColor: theme.colors.white,
      width: '100%',
      borderRadius: theme.borderRadius.large,
      [Theme.media.down('mid')]: {
        flexDirection: 'column',
      },
    },
    innerWrapper: {
      width: '50%',
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
    image: {
      width: '50%',
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
    inputWrapper: {
      width: '55%',
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
    input: {
      paddingLeft: theme.spacing.value(1),
      paddingRight: theme.spacing.value(1),
    },
    button: {
      width: '45%',
      'transition': 'all 0.2s',
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
  }),
  true,
)
