import { TextInputComposition } from '@codeleap/common'
import { variantProvider } from '../theme'

const createTextInputStyle =
  variantProvider.createVariantFactory<TextInputComposition>()
const defaultStyles = variantProvider.getDefaultVariants('TextInput')

const defaultVariant = createTextInputStyle((theme) => ({
  ...defaultStyles.default(theme),
  wrapper: {
    ...defaultStyles.default(theme).wrapper,
    ':focus': {
      backgroundColor: 'blue',
      border: '1px solid blue',
    },
    // height: 52,
  },
  innerWrapper: {
    ...defaultStyles.default(theme).innerWrapper,
    borderRadius: theme.borderRadius.large,
    height: 52,
    border: `1px solid ${theme.colors.blue400}`,
    ':hover': {
      boxShadow: 'rgb(0 0 0 / 8%) 0px 0px 25px',
    },
    ':focus-within': {
      border: `1px solid ${theme.colors.blue400}`,
    },
  },
  icon: {
    ...defaultStyles.default(theme).icon,
    color: theme.colors.neutral,
  },
  textField: {
    ...defaultStyles.default(theme).textField,
    color: theme.colors.black,
    caretColor: theme.colors.black
  },
}))

export const AppTextInputStyles = {
  ...defaultStyles,
  default: defaultVariant,
}
