import { api } from '@/app'
import { ReactQuery } from '@codeleap/common'
import { Toast } from '@codeleap/web'

const BASE_URL = 'profiles/'

export async function createNewsletter(newPost) {

  try {
    const res = await api.post(`${BASE_URL}/subscribe-to-newsletter/`, newPost)

    if (res.status === 200 || res.status === 204) {
      Toast.success(
        { title: 'Newsletter created succesfully' },

      )
    }
  } catch ({ err }) {
    const errorMessage = err?.response?.data?.message
    Toast.error({
      title: errorMessage,
    })
  }
}

export function useProfile(args) {

  const newsLetterQuery = ReactQuery.useMutation({ mutationKey: [''], mutationFn: createNewsletter })

  return {
    newsLetterQuery,
  }
}
