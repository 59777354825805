import { makeFetcher } from '@codeleap/common'
import { Settings } from './Settings'
import { logger } from './logger'
import { withFirebase } from '@/services'

const client = makeFetcher(Settings, {
  logger,
  requestMiddleware: [

  ],
})

export const api = client
