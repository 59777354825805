import { LocalStorageKeys, Theme, variantProvider, React } from '@/app'
import { AppStatusOverlay, Page, View, Link, Button, Text, CenterWrapper } from '@/components'
import { AppStatus, Session, useAppSelector } from '@/redux'
import { onMount, onUpdate, useComponentStyle } from '@codeleap/common'

import { Home, About, Banner } from '@/layout'

export const Overlays = () => {
  return (
    <>
      <AppStatusOverlay />
      {/* <DebugModal/> */}
    </>
  )
}

const IndexPage: React.FC = () => {

  return (
    <Page title='Home' center={false}>
      <View variants={['column']}>
        <Home />
        {/* <About /> */}
        <CenterWrapper
          variants={['justifySpaceBetween']}
        >
          <Banner />
        </CenterWrapper>
      </View>
    </Page>
  )
}

export default IndexPage
