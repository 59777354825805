import { onMount, onUpdate, useCodeleapContext, ReactQuery } from '@codeleap/common'
import { Provider as ReduxProvider } from 'react-redux'
import { React, LocalStorageKeys, Theme, variantProvider, AppThemeModes } from './app'
import { Session, store, useAppSelector } from './redux'
import { Global } from '@emotion/react'
import { globalStyleDark, globalStyleLight } from './app/stylesheets/Global'
import { ToastContainer } from 'react-toastify'

import { withFirebase } from './services/firebase'
import { useWindowSize } from '@codeleap/web'
import { queryClient } from './services/api'

const QueryClientProvider = ReactQuery.QueryClientProvider

const Toaster = () => {
  const { currentTheme } = useCodeleapContext()

  return <>
    <Global styles={currentTheme === 'dark' ? globalStyleDark : globalStyleLight}/>
    <ToastContainer

    />
  </>
}

const Root = ({ children }) => {
  const { currentTheme } = useCodeleapContext()

  const windowSize = useWindowSize()
  onMount(() => {
    const savedTheme = localStorage.getItem(LocalStorageKeys.THEME) as AppThemeModes
    if (savedTheme) {
      variantProvider.setColorScheme(savedTheme)
    }
  })

  onUpdate(() => {
    localStorage.setItem(LocalStorageKeys.THEME, currentTheme as AppThemeModes)
  }, [currentTheme])
  variantProvider.theme.values.height = windowSize[1]
  variantProvider.theme.values.width = windowSize[0]
  return (

    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <Toaster />
        <Wrapper h={windowSize[1]} w={windowSize[0]}>
          {children}
        </Wrapper>
      </QueryClientProvider>
    </ReduxProvider>

  )
}

const Wrapper = ({ children, h, w }) => {

  variantProvider.theme.values.height = h
  variantProvider.theme.values.width = w

  return <>
    {children}
  </>
}
export default Root
