import { React, variantProvider, Settings } from '@/app'
import { onUpdate, useCodeleapContext, useState } from '@codeleap/common'
import { Session } from '@/redux'
import { Image, Touchable } from '@/components'
type LogoProps = {
  variants?: string | string[]
  switchServerOnPress?: boolean
  style?: {}
}

export function Logo(props: LogoProps) {

  const { currentTheme } = useCodeleapContext()

  const source =
    props.variants?.includes('black') ||
    (currentTheme === 'light' && !props.variants)
      ? 'codeleap_logo_black.png'
      : 'rocket.png';

  const [numberOfPresses, setPresses] = useState(0)

  onUpdate(() => {
    if (numberOfPresses === 10) {
      Session.setMode().then(() => {
        setPresses(0)
      })
    }
  }, [numberOfPresses])

  const image = (
    <Image source={'images/wesnag_logo.png'} css={[styles.image, props?.style]} />
  );

  if (props.switchServerOnPress && Settings.Environment.IsDev) {
    return <Touchable onPress={() => setPresses(n => n + 1) }
      debugName={'Click on Logo'}>
      {image}
    </Touchable>
  }

  return image
}

const styles = variantProvider.createComponentStyle({
  image: {
    height: undefined,
    width: '75%',
    alignSelf: 'center',
  },
}, true)
