import React from 'react'
import { variantProvider, Settings, Theme } from '@/app'
import { assignTextStyle, useComponentStyle } from '@codeleap/common'

import { View, Image, Text, CenterWrapper, Link, Icon } from '@/components'

export type HomeProps = {}

export const Home: React.FC<HomeProps> = (props) => {
  const styles = useComponentStyle(componentStyles)

  const isSmall = Theme.hooks.down('mid')

  return (
    <View
      css={styles.wrapper}
      variants={['relative', 'fullHeight', 'justifyCenter']}
    >
      {!isSmall && <Icon name={'topLeft'} variants={['absolute']} style={styles.topLeftIcon} />}
      {!isSmall && <Icon name={'bottomLeft'} variants={['absolute']} style={styles.bottomLeftIcon} />}
      {!isSmall && <Icon name={'middle'} variants={['absolute']} style={styles.middleIcon} />}
      <CenterWrapper
        variants={['justifySpaceBetween']}
        styles={{
          innerWrapper: styles.centerWrapper,
        }}
      >
        <View
          css={styles.descriptionWrapper}
        >
          <View variants={['column']}>
            <Text variants={['h1', 'black800', 'noBreakLine']} text='One Inspection,' />

            <View variants={['row', 'relative', 'gap:1.5']}>
              <Text variants={['h1', 'black800']} text='One' />
              <View variants={['row', 'relative']}>
                <Text variants={['h1', 'black800']} text='Community,' />
                <Icon name={'textUnderline'} variants={['absolute']} style={styles.textUnderlineIcon} />
              </View>
            </View>

            <View variants={['row', 'gap:1.5']}>
              <Text variants={['h1', 'black800', 'noBreakLine']} text={`One App,`} />
              <Text css={styles.comunityText} text={`WeSNAG`} variants={['h1', 'blue700']} />
            </View>
          </View>

          <Text
            variants={['black800', 'marginTop:2', 'marginBottom:2']}
            text={'Throw away that notepad and pen and use technology to enhance management and subcontractor engagement in real time when carrying out on site inspections'}
          />

          <View
            variants={['gap:2', 'marginVertical:2']}
            responsiveVariants={{
              mid: ['alignCenter'],
            }}
          >
            <Link target='_blank' to={'https://apps.apple.com/app/id1660278677'}>
              <Icon name={'appStore'} style={styles.linkButton} />
            </Link>
            <Link target='_blank' to={'https://play.google.com/store/apps/details?id=app.wesnag'}>
              <Icon name={'googlePlay'} />
            </Link>
          </View>
        </View>
      </CenterWrapper>
      <View css={styles.imageWrapper} variants={['absolute']}>
        <Image source={'home_phone.png'} css={styles.imagePhone} />
      </View>
    </View>
  )
}

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    minHeight: '110vh',
    width: '100vw',
    maxWidth: '100%',
    backgroundColor: theme.colors.primary,
    [Theme.media.down('mid')]: {
      minHeight: '60vh',
    },
  },
  image: {},
  linkButton: {
    width: 150,
    height: 50,
  },
  imageWrapper: {
    right: 0,
    top: '15%',
    width: '40%',
  },
  imagePhone: {
    objectFit: 'cover',
    opacity: 1,
    visibility: 'visible',
    [Theme.media.down('xxlarge')]: {
      width: 800,
    },
    [Theme.media.down('xlarge')]: {
      width: 600,
      top: '20%',
    },
    [Theme.media.down('mid')]: {
      width: '100%',
    },
    [Theme.media.down('small')]: {
      opacity: 0,
      visibility: 'hidden',
    },
  },
  descriptionWrapper: {
    zIndex: 9,
    width: '50%',
    ...theme.presets.column,
    ...theme.presets.justifyCenter,
    ...theme.presets.alignStart,
    
    [Theme.media.down('mid')]: {
      width: '100%',
      ...theme.presets.alignCenter,
      ...theme.presets.textCenter,
    },
  },
  centerWrapper: {
    [Theme.media.down('mid')]: {
      flexDirection: 'column-reverse',
    },
  },
  boldText: {
    display: 'unset',
    ...Theme.spacing.marginHorizontal(0.8),
  },
  leftIcon: {
    left: 0,
  },
  topLeftIcon: {
    left: 0,
    top: '30%',
    // zIndex: -1,
  },
  bottomLeftIcon: {
    left: 0,
    top: '80%',
  },
  middleIcon: {
    left: '50%',
    top: '75%',
  },
  textUnderlineIcon: {
    right: 0,
    zIndex: -1,
    maxWidth: '100%',
  },
  comunityText: {
    display: 'inline-block',
  },
}))
