import React from 'react'

import { Theme, variantProvider, logger } from '@/app'
import { View, Text, Image, CenterWrapper, Link, Icon } from '@/components'

import { useComponentStyle } from '@codeleap/common'

export type AboutProps = {}

export const About: React.FC<AboutProps> = (props) => {
  const styles = useComponentStyle(componentStyles)

  const renderIcons = () => {
    return (
      <View variants={['gap:2']}>
        <Link to={'/'}>
          <Icon name={'appStore'} style={styles.linkButton} />
        </Link>
        <Link to={'/'}>
          <Icon name={'googlePlay'} />
        </Link>
      </View>
    )
  }

  const renderText = () => {
    return (
      <Text
        variants={['h2']}
        responsiveVariants={{
          mid: ['textCenter'],
        }}
        css={styles.text}
        text={'Download and start using Cherry for free today'}
      />
    )
  }

  return (
    <CenterWrapper
      styles={{
        innerWrapper: styles.wrapper,
      }}
      variants={[
        'column',
        'justifyCenter',
        'alignCenter',
        'gap:6',
        'paddingVertical:10',
      ]}
    >
      {renderText()}
      {renderIcons()}
    </CenterWrapper>
  )
}

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    width: '100vw',
    maxWidth: '100%',
    backgroundColor: theme.colors.white,
    gap: theme.spacing.value(6),
  },
  image: {
    width: '50%',
  },
  linkButton: {
    width: 150,
    height: 50,
  },
  imagePhone: {
    width: 300,
    height: 430,
  },
  text: {
    color: theme.colors.textPrimary,
  },
}))
