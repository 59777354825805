import { React, variantProvider, Theme } from '@/app'
import {
  View,
  Button,
  CenterWrapper,
  Drawer,
  Logo,
  Image,
  Link,
  Avatar,
} from '@/components'
import {
  onUpdate,
  useCodeleapContext,
  useComponentStyle,
  useState,
} from '@codeleap/common'
import { useWindowSize, useScrollEffect } from '@codeleap/web'
import { AppStatus, useAppSelector } from '@/redux'
import { AuthModal } from './AuthModal'
import { navigate } from 'gatsby'

export const Header = () => {
  const { currentTheme } = useCodeleapContext()

  const styles = useComponentStyle(componentStyles)
  const [below, setBelow] = useState(false)

  useScrollEffect(setBelow, Theme.values.maxHeaderMenuHeight)

  return (
    <>
      <CenterWrapper
        styles={{
          innerWrapper: styles.wrapper,
          wrapper: below ? styles.floatingHeaderBelow : styles.floatingHeader,
        }}
      >
        <Link to={'/'} css={styles.logoWrapper}>
          <Logo style={styles.logo} />
        </Link>
      </CenterWrapper>
    </>
  )
}

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    ...theme.presets.row,
    // ...theme.presets.alignCenter,
    ...theme.spacing.padding(1),
    justifyContent: 'unset',
  },
  logo: {
    width: 140,
    [theme.media.down('small')]: {
      width: 120,
    },
  },
  logoWrapper: {
    marginRight: 'auto',
    [theme.media.down('small')]: {
      marginRight: 0,
      display: 'flex',
    },
  },
  floatingHeader: {
    position: 'fixed',
    zIndex: theme.values.zIndex.header,
    top: 0,
    left: 0,
    right: 0,
    transition: '0.3s ease',
    // backgroundColor: 'yellow',
  },
  floatingHeaderBelow: {
    position: 'fixed',
    zIndex: 999999,
    transition: '0.3s ease',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: '#D5E2FF',
  },
  nav: {
    ...theme.presets.row,
    // ...theme.presets.alignCenter,
    gap: theme.spacing.value(2),

    [theme.media.down('small')]: {
      ...theme.presets.column,
      backgroundColor: theme.colors.background,
      alignItems: 'stretch',
      height: '100vh',
    },
  },
  navButton: {},
  navLink: {
    [theme.media.down('small')]: {
      // ...theme.presets.textCenter,
      textDecoration: 'none',
    },
  },
  themeSwitch: {
    color: theme.colors.textH,
  },
  image: {
    height: undefined,
    width: '75%',
    // alignSelf: 'center',
  },
}))
